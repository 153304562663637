import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import SEO from "../components/seo"
import { renderRichText } from "../lib/renderRichText"

const ContactPage = props => {
  const { contentfulContactPage } = props.data
  const description = documentToPlainTextString(contentfulContactPage.info.json)

  return (
    <>
      <SEO
        title={contentfulContactPage.title}
        description={description}
        lang={contentfulContactPage.node_locale}
      />

      <Container className="p-5 paper centered">
        <Row>
          <Col md={12}>
            <h1 className="font-weight-bold">{contentfulContactPage.title}</h1>
            <div>{renderRichText(contentfulContactPage.info.json)}</div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query contactPageQuery($locale: String!) {
    contentfulContactPage(
      pageName: { eq: "contact" }
      node_locale: { eq: $locale }
    ) {
      info {
        json
      }
      node_locale
      title
    }
  }
`
export default ContactPage
